import schttp from 'public/src/services/schttp'

let langTask
export function getCouponLanguage(LANGUAGE_KEYS) {
  if (!langTask) {
    langTask = schttp({
      url: '/api/home/component/couponLanguage',
      method: 'GET',
      params: {
        languageKeys: LANGUAGE_KEYS
      },
    })
  }
  return langTask
}
