/**
 * 券组件需要使用的多语言keys
 */
export const LANGUAGE_KEYS = [
  'SHEIN_KEY_PWA_30827', // Capped at {0}	
  'SHEIN_KEY_PWA_30829', // Category Coupon	
  'SHEIN_KEY_PWA_30971', // Shipping Coupon
  'SHEIN_KEY_PWA_30969', // Special Coupon
  'SHEIN_KEY_PWA_30970', // Store Coupon	
  'SHEIN_KEY_PWA_30834', // Shop
  'SHEIN_KEY_PWA_30835', // Claim
  'SHEIN_KEY_PWA_30828', // {0} OFF subscription fee
  'SHEIN_KEY_PWA_30540', // Orders {0}+
  'SHEIN_KEY_PWA_30958', // Pay with {0}
  'SHEIN_KEY_PWA_30959', // Stackable
  'SHEIN_KEY_PWA_30830', // Best choice
  'SHEIN_KEY_PWA_30961', // NEW
  'SHEIN_KEY_PWA_30977', // Expires in {0}
  'SHEIN_KEY_PWA_30978', // Code:{0}
  'SHEIN_KEY_PWA_30987', // {0} Max
  'SHEIN_KEY_PWA_31027', // Expired
  'SHEIN_KEY_PWA_31028', // All claimed
  'SHEIN_KEY_PWA_31029', // Expanded
  'SHEIN_KEY_PWA_31030', // Used
  'SHEIN_KEY_PWA_31031', // Claimed
  'SHEIN_KEY_PWA_31335', // Invalid
  'SHEIN_KEY_PWA_20959',
  'SHEIN_KEY_PWA_20958',
  'SHEIN_KEY_PWA_34576',
  // 附加组件
  'SHEIN_KEY_PWA_31205', // Add

  // 适用范围多语言
  'SHEIN_KEY_PWA_20922',
  'SHEIN_KEY_PWA_23527',
  'SHEIN_KEY_PWA_23526',
  'SHEIN_KEY_PWA_23526',
  'SHEIN_KEY_PWA_23528',
  'SHEIN_KEY_PWA_20908',
  'SHEIN_KEY_PWA_20908',
  'SHEIN_KEY_PWA_20910',
  'SHEIN_KEY_PWA_20909',
  'SHEIN_KEY_PWA_21348',
  'SHEIN_KEY_PWA_20918',
  'SHEIN_KEY_PWA_20919',
  'SHEIN_KEY_PWA_21316',
  'SHEIN_KEY_PWA_21393',
  'SHEIN_KEY_PWA_26436',
  'SHEIN_KEY_PWA_26435',
  'SHEIN_KEY_PWA_26437',
  'SHEIN_KEY_PWA_26438',
  'SHEIN_KEY_PWA_26154',
  'SHEIN_KEY_PWA_26332',
  'SHEIN_KEY_PWA_26174',
  'SHEIN_KEY_PWA_26334',
  'SHEIN_KEY_PWA_20908',
  'SHEIN_KEY_PWA_20910',
  'SHEIN_KEY_PWA_20909',
  'SHEIN_KEY_PWA_21348',
  'SHEIN_KEY_PWA_20910',
  'SHEIN_KEY_PWA_20919',
  'SHEIN_KEY_PWA_26625',
  'SHEIN_KEY_PWA_26626',
  'SHEIN_KEY_PWA_20910',
  'SHEIN_KEY_PWA_26625',
  'SHEIN_KEY_PWA_26626',
  'SHEIN_KEY_PWA_20908',
  'SHEIN_KEY_PWA_23420',
  'SHEIN_KEY_PWA_26331',
  'SHEIN_KEY_PWA_20918',
  'SHEIN_KEY_PWA_20919',
  'SHEIN_KEY_PWA_26436',
  'SHEIN_KEY_PWA_26435',
  'SHEIN_KEY_PWA_26154',
  'SHEIN_KEY_PWA_26332',
  'SHEIN_KEY_PWA_20908',
  'SHEIN_KEY_PWA_20910',
  'SHEIN_KEY_PWA_23412',
  'SHEIN_KEY_PWA_26331',
  'SHEIN_KEY_PWA_23414',
  'SHEIN_KEY_PWA_26333',
  'SHEIN_KEY_PWA_23414',
  'SHEIN_KEY_PWA_26333',
  'SHEIN_KEY_PWA_23420',
  'SHEIN_KEY_PWA_26331',
  'SHEIN_KEY_PWA_23422',
  'SHEIN_KEY_PWA_26333',
  'SHEIN_KEY_PWA_26499',
  'SHEIN_KEY_PWA_26497',
  'SHEIN_KEY_PWA_26503',
  'SHEIN_KEY_PWA_26501',
  'SHEIN_KEY_PWA_26500',
  'SHEIN_KEY_PWA_26498',
  'SHEIN_KEY_PWA_26504',
  'SHEIN_KEY_PWA_26502',
  'SHEIN_KEY_PWA_21348',
  'SHEIN_KEY_PWA_28876',
  'SHEIN_KEY_PWA_28875',
  'SHEIN_KEY_PWA_28874',
  'SHEIN_KEY_PWA_28873',
  'SHEIN_KEY_PWA_23412',
  'SHEIN_KEY_PWA_23413',
  'SHEIN_KEY_PWA_23414',
  'SHEIN_KEY_PWA_23416',
  'SHEIN_KEY_PWA_23420',
  'SHEIN_KEY_PWA_23421',
  'SHEIN_KEY_PWA_23422',
  'SHEIN_KEY_PWA_23423',
  'SHEIN_KEY_PWA_20908',
  'SHEIN_KEY_PWA_20918',
  'SHEIN_KEY_PWA_20919',
  'SHEIN_KEY_PWA_20908',
  'SHEIN_KEY_PWA_22611',
  'SHEIN_KEY_PWA_28756',
  'SHEIN_KEY_PWA_28744',
  'SHEIN_KEY_PWA_28745',
  'SHEIN_KEY_PWA_17523',
  'SHEIN_KEY_PWA_18335',
  'SHEIN_KEY_PWA_17155',
  'SHEIN_KEY_PWA_17515',
  'SHEIN_KEY_PWA_22049',
  'SHEIN_KEY_PWA_31101',
  'SHEIN_KEY_PWA_31104',
  'SHEIN_KEY_PWA_28755',
  'SHEIN_KEY_PWA_28743',
  'SHEIN_KEY_PWA_31102',
  'SHEIN_KEY_PWA_28878',
  'SHEIN_KEY_PWA_31103',
  'SHEIN_KEY_PWA_29039',
  'SHEIN_KEY_PWA_29535',
  'SHEIN_KEY_PWA_15807',
  'SHEIN_KEY_PWA_36148',
  'SHEIN_KEY_PWA_36149',
  'SHEIN_KEY_PWA_35191',
  'SHEIN_KEY_PWA_36396',
]
