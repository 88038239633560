import { createApp } from 'vue'
import StickyFreeShippingVue from './StickyFreeShipping.vue'

let instance = null

function createInstance(params) {
  try {
    if(instance) {
      console.log('sticky-free-shipping instance is exist')
      return
    }

    const appDom = document.createElement('div')
    instance = createApp(StickyFreeShippingVue, params).mount(appDom)
    document.body.appendChild(appDom)
    console.log('sticky-free-shipping create success')
    setTimeout(() => {
      instance.show(params)
    }, 0)
  } catch (error) {
    console.log(error)
  }
}

export default {
  show(params) {
    if (!instance) {
      createInstance(params)
    } else {
      instance.show(params)
    }
  },
  update(params) {
    if (instance) {
      // 更新props
      instance.update(params)
    }
  },
  hidden() {
    if (instance) {
      return instance.hidden()
    }
  }
}
